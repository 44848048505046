'use client';

import { useRouter } from 'next/navigation';

export default function Button({ urlPath, children }) {
  const router = useRouter();
  return (
    <button
      className="btn border-0 button-style flex items-center text-center"
      onClick={() => router.push('/' + urlPath)}
    >
      {children}
    </button>
  );
}
